<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 优惠规则
 * @Date: 2020-11-08 19:03:11
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-08 19:18:56
 * @FilePath: /yue_quanzhan_h5_own/src/views/localLife/comeOn/PreferentialRules.vue
-->
<template>
  <div class="content">
    <div v-for="(item, index) in rulesInfo.rule" :key="'$'+index">
      <p>{{ item.title }}</p>
      <ul>
        <li v-for="(rules, rIndex) in item.content" :key="'#'+rIndex">{{ rules }}</li>
      </ul>
    </div>
    <p>{{ rulesInfo.tips }}</p>
  </div>
</template>

<script>
import {
  getXJRulesInfo
} from '@/services/comeOn'

export default {
  data() {
    return {
      rulesInfo: []
    }
  },
  created() {
    this.getRulesInfo()
  },
  methods: {
    getRulesInfo() {
      getXJRulesInfo().then(res => {
        if (Number(res.code) === 200) {
          this.rulesInfo = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 15px 10px;
  background-color: #f8f8f8;
  min-height: 100vh;
  text-align: left;
  line-height: 22px;
  color: #333333;
}
</style>
